import { render, staticRenderFns } from "./index.vue?vue&type=template&id=7480c5e0&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=7480c5e0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.2_cache-loader@4.1.0_webpack@4.47.0__css-loader@3.6.0_webpack@4.47.0__lodash_y2tmxmhnpgfcthxjlq7ugpu5em/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\work\\platform-back-front\\node_modules\\.pnpm\\vue-hot-reload-api@2.3.4\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7480c5e0')) {
      api.createRecord('7480c5e0', component.options)
    } else {
      api.reload('7480c5e0', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=7480c5e0&", function () {
      api.rerender('7480c5e0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Editor/index.vue"
export default component.exports